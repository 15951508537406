/* eslint-disable @next/next/no-img-element */
import { cx } from '@/shared/utils'

interface IMarketThumbnailProps {
  url: string
  size: {
    width: string
    height: string
  }
}

const MarketThumbnail = ({ url, size }: IMarketThumbnailProps) => {
  const imgUrlValue = url || '/img/placeholder/item-card-replace.png'

  return (
    <div
      className={cx(
        size.width,
        size.height,
        'shrink-0 aspect-square rounded-sm overflow-hidden bg-gradient-to-b from-black to-transparent via-transparent bg-gray-700',
      )}>
      <img
        className="object-fill w-full h-full"
        alt="collection_ranking_thumbnail"
        src={imgUrlValue}
        onError={e => {
          e.currentTarget.src = '/img/placeholder/item-card-replace.png'
        }}
      />
    </div>
  )
}

export default MarketThumbnail
