/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQueryErrorResetBoundary,
} from '@tanstack/react-query'
import { range } from 'lodash'
import { useRouter } from 'next/router'
import { Suspense, useRef } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { CommonErrorFallback, TabBar } from '@/components/common'
import { ITabData } from '@/components/common/TabBar'
import { TAB_BAR_THEME } from '@/shared/constants'
import {
  useBalance,
  useBenefits,
  useCategoryTabList,
  useIntersectionObserver,
} from '@/shared/hooks'
import {
  IGetCollectionCategoriesResponse,
  useGetCollectionCategories,
} from '@/shared/services/collections'
import { IUtilitiesItem, useGetOnSaleInfo } from '@/shared/services/market'
import { IAllCoinPrice } from '@/shared/types'
import { IMarketOnSaleItem } from '@/shared/types/items'

import { MarketTitle } from '../common'
import MarketOnSaleCard from './MarketOnSaleCard'

interface IMarketOnSaleSectionDesignProps {
  targetRef: React.RefObject<HTMLDivElement>
  benefitList: IUtilitiesItem[] | undefined
  onSaleDataList: InfiniteData<IMarketOnSaleItem> | undefined
  tabList: ITabData<string>[] | null | undefined
  currentTab: string | undefined
  hasNextPage: boolean | undefined
  getItemUtilitiesFetchStatus?: 'loading' | 'error' | 'success'
  coinPrice: IAllCoinPrice | undefined
  errorGetCollectionCategories: unknown
  isLoadingByFetchUtilities: boolean
  currency: {
    icon: '₩' | '$'
    name: 'USD' | 'KRW'
  }
  fetchNextPage: (
    options: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<IMarketOnSaleItem, unknown>>
  refetchGetCollectionCategories: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<IGetCollectionCategoriesResponse, unknown>>
}

const MarketOnSaleSkelton = () => {
  const { t } = useTranslation(['common', 'web-market-home'])
  return (
    <div className="flex flex-col gap-y-4 p-4 mt-6">
      <MarketTitle title={t('web-market-home:market-home.trending-items-title')} />
      <TabBar.Skeleton type="filter" />

      {range(5).map(index => (
        <MarketOnSaleCard.Skelton key={`market-on-sale-skeleton-card-${index}`} />
      ))}
    </div>
  )
}

const MarketOnSaleErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common', 'web-market-home'])
  return (
    <div className="p-4 mt-4 bg-bgPrimary">
      <MarketTitle
        title={t('web-market-home:market-home.trending-items-title')}
        wrapperClassNames={'mb-4'}
      />
      <CommonErrorFallback
        error={error}
        bgColor="bg-bgPrimary"
        title={t('common:error-data')}
        handleRetry={resetErrorBoundary}
      />
    </div>
  )
}

const MarketOnSaleDesign = ({
  tabList,
  targetRef,
  currentTab,
  coinPrice,
  currency,
  benefitList,
  onSaleDataList,
  hasNextPage,
  isLoadingByFetchUtilities,
  errorGetCollectionCategories,
  refetchGetCollectionCategories,
}: IMarketOnSaleSectionDesignProps) => {
  const { t } = useTranslation(['common', 'web-market-home'])
  if (onSaleDataList === undefined) return <></>

  const hasOnSaleData = onSaleDataList.pages.length !== 0

  if (tabList === null) {
    return (
      <div className="p-4 mt-4 bg-bgPrimary">
        <MarketTitle
          title={t('web-market-home:market-home.trending-items-title')}
          wrapperClassNames={'mb-4'}
        />
        <CommonErrorFallback
          error={errorGetCollectionCategories}
          bgColor="bg-bgPrimary"
          title={t('common:error-trending-item')}
          handleRetry={refetchGetCollectionCategories}
        />
      </div>
    )
  }

  return (
    <section className="p-4 mt-6 mb-4 bg-bgPrimary">
      <MarketTitle title={t('web-market-home:market-home.trending-items-title')} />

      <TabBar
        theme={TAB_BAR_THEME.handler}
        tabData={tabList}
        selectedTab={currentTab}
        wrapperClassNames={'mt-4 mb-2'}
      />

      {hasOnSaleData ? (
        <>
          {onSaleDataList.pages.map((item, index) => (
            <MarketOnSaleCard
              {...item}
              currency={currency}
              coinPrice={coinPrice}
              benefitList={benefitList}
              isLoadingByFetchUtilities={isLoadingByFetchUtilities}
              key={`market-on-sale-card-${index}`}
            />
          ))}
          {hasNextPage && (
            <>
              <div ref={targetRef} className="flex flex-col gap-y-4 mt-4">
                {range(2).map(index => (
                  <MarketOnSaleCard.Skelton key={`market-on-sale-skeleton-card-${index}`} />
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="w-full py-[40px] mt-6">
          <p className="w-full text-base text-center text-textSecondary">
            {t('common:common.empty-item-on-sales')}
          </p>
        </div>
      )}
    </section>
  )
}

const MarketOnSaleWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ErrorBoundary onReset={reset} FallbackComponent={MarketOnSale.ErrorFallback}>
      <Suspense fallback={<MarketOnSale.Skelton />}>
        <MarketOnSale />
      </Suspense>
    </ErrorBoundary>
  )
}

const MarketOnSale = () => {
  const { pathname } = useRouter()
  const targetRef = useRef<HTMLDivElement>(null)
  const { allCoinPrice: coinPrice, currency } = useBalance()
  const {
    data: collectionCategoryData,
    error: errorGetCollectionCategories,
    refetch: refetchGetCollectionCategories,
  } = useGetCollectionCategories(pathname)

  const { tabList, currentTab } = useCategoryTabList({ data: collectionCategoryData })
  const {
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    data: onSaleDataList,
  } = useGetOnSaleInfo({
    params: {
      categoryId: currentTab,
    },
  })

  const { storedBenefits, isLoadingByFetchUtilities } = useBenefits({
    data: onSaleDataList,
  })

  useIntersectionObserver({
    onIntersect: () => {
      !isFetchingNextPage && hasNextPage && fetchNextPage()
    },
    targetRef,
  })

  return (
    <MarketOnSale.Design
      targetRef={targetRef}
      tabList={tabList}
      currency={currency}
      coinPrice={coinPrice}
      currentTab={currentTab}
      hasNextPage={hasNextPage}
      benefitList={storedBenefits}
      fetchNextPage={fetchNextPage}
      onSaleDataList={onSaleDataList}
      isLoadingByFetchUtilities={isLoadingByFetchUtilities}
      errorGetCollectionCategories={errorGetCollectionCategories}
      refetchGetCollectionCategories={refetchGetCollectionCategories}
    />
  )
}

export default MarketOnSale
MarketOnSale.Design = MarketOnSaleDesign
MarketOnSale.Skelton = MarketOnSaleSkelton
MarketOnSale.ErrorFallback = MarketOnSaleErrorFallback
MarketOnSale.WithErrorBoundary = MarketOnSaleWithErrorBoundary
