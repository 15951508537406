/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'

import { PriceLabel } from '@/components/common'
import { ROUTE_PATH } from '@/shared/constants'
import { ICollectionRankingItem } from '@/shared/types'
import { cx, getSign } from '@/shared/utils'

import { MarketThumbnail } from '../common'

type MarketRankingInfoProp = Pick<
  ICollectionRankingItem,
  | 'minPrice'
  | 'isOriginals'
  | 'paymentType'
  | 'categoryTitle'
  | 'collectionTitle'
  | 'minPricePercentage'
  | 'currentTradingVolume'
  | 'currentTradingVolumePercentage'
>

const MarketRankingCardSkelton = () => {
  return (
    <div className="flex w-full py-2 gap-x-4 shrink-0">
      <div className="bg-bgQuarternary rounded-sm shrink-0 w-[24px] h-[24px]" />
      <div className="bg-bgQuarternary rounded-xl shrink-0 w-[108px] h-[108px]" />

      <div className="w-full">
        <div className="w-[40px] h-[18px] bg-bgQuarternary rounded shrink-0" />
        <div className="max-w-[180px] h-[22px] bg-bgQuarternary rounded-sm shrink-0 mt-1" />
        <div className="w-[120px] h-[12px] bg-bgQuarternary rounded-sm shrink-0 mt-1" />
      </div>
    </div>
  )
}

const MarketRankingInfo = ({
  minPrice,
  isOriginals,
  paymentType,
  categoryTitle,
  collectionTitle,
  minPricePercentage,
  currentTradingVolume,
  currentTradingVolumePercentage,
}: MarketRankingInfoProp) => {
  const { t } = useTranslation('common')

  const currentTradingVolumePercentageValue =
    currentTradingVolumePercentage === '' ? null : currentTradingVolumePercentage

  const currentMinPricePercentageValue = minPrice === '' ? null : minPrice

  const signColorMappings = {
    '+': 'text-green-600',
    '-': 'text-textError',
    '': 'text-textSecondary',
  } as const

  const getSignByMinPricePercentage = getSign(minPricePercentage)
  const getSignByCurrentTradingVolume = getSign(currentTradingVolumePercentage)

  return (
    <div>
      <div className="flex items-center gap-x-1 mb-1">
        <p className="max-w-[170px] font-medium leading-5 truncate text-textPrimary">
          {collectionTitle}
        </p>

        {isOriginals && (
          <img alt="collection_ranking_thumbnail_logo" src="/img/badge/originals-badge.svg" />
        )}
      </div>

      <div className="flex items-center gap-x-3">
        <div className="px-2 py-1 bg-bgTertiary rounded font-medium	w-fit">
          <p className="text-xs font-medium leading-normal text-textAccent">{categoryTitle}</p>
        </div>
      </div>

      <div className="flex items-baseline gap-x-2">
        <p className="text-xs font-medium text-textSecondary">
          {t('common.collection-total-volume')}
        </p>

        <div>
          <div className="flex items-center gap-x-1">
            <PriceLabel
              size="sm"
              isShowPaymentType
              paymentType={paymentType}
              price={Number(currentTradingVolume)}
            />
          </div>

          <p
            className={cx(
              'ml-[14px] text-xs font-semibold',
              signColorMappings[getSignByCurrentTradingVolume],
            )}>
            {currentTradingVolumePercentageValue && `${currentTradingVolumePercentageValue}`}
          </p>
        </div>
      </div>

      <div className="flex gap-x-2 mt-[2px]">
        <p className="text-xs font-medium text-textSecondary pt-1">{t('common.floor-price')}</p>
        <div>
          <PriceLabel
            size="sm"
            isShowPaymentType
            paymentType={paymentType}
            price={Number(minPrice)}
          />

          <p
            className={cx(
              'ml-[14px] text-xs font-semibold',
              signColorMappings[getSignByMinPricePercentage],
            )}>
            {currentMinPricePercentageValue && `${minPricePercentage}`}
          </p>
        </div>
      </div>
    </div>
  )
}

const MarketRankingCard = ({
  image,
  ranking,
  minPrice,
  isOriginals,
  paymentType,
  categoryTitle,
  collectionTitle,
  collectionAddress,
  minPricePercentage,
  currentTradingVolume,
  currentTradingVolumePercentage,
  isLastItem,
}: ICollectionRankingItem) => {
  const { push } = useRouter()

  const handleClick = useCallback(() => {
    push(ROUTE_PATH.COLLECTION_DETAIL.replace(':collectionAddress', collectionAddress))
  }, [push, collectionAddress])

  return (
    <div className="flex pt-4 gap-x-4" onClick={handleClick}>
      <div
        className={cx(
          'w-full flex pb-2 gap-x-4',
          !isLastItem && 'border-borderQuarternary border-b',
        )}>
        <MarketThumbnail
          url={image}
          size={{
            width: 'w-1/3',
            height: 'h-fit',
          }}
        />
        <p className="w-[26px] text-xl text-center	font-medium leading-tight text-textAccent">
          {ranking}
        </p>
        <MarketRankingCard.Info
          minPrice={minPrice}
          isOriginals={isOriginals}
          paymentType={paymentType}
          categoryTitle={categoryTitle}
          collectionTitle={collectionTitle}
          minPricePercentage={minPricePercentage}
          currentTradingVolume={currentTradingVolume}
          currentTradingVolumePercentage={currentTradingVolumePercentage}
        />
      </div>
    </div>
  )
}

export default MarketRankingCard

MarketRankingCard.Info = MarketRankingInfo
MarketRankingCard.Skelton = MarketRankingCardSkelton
