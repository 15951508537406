import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { PriceLabel } from '@/components/common'
import { ROUTE_PATH } from '@/shared/constants'
import { IUtilitiesItem } from '@/shared/services/market'
import { IAllCoinPrice } from '@/shared/types'
import { IMarketOnSaleItem } from '@/shared/types/items'
import { convertBalanceToCurrencyWithSymbol, getBenefitItemInfo } from '@/shared/utils'

import { MarketThumbnail } from '../common'
import MarketOnSaleBenefit from './MarketOnSaleBenefit'

interface IMarketOnSaleCardProps extends IMarketOnSaleItem {
  isLoadingByFetchUtilities: boolean
  getItemUtilitiesFetchStatus?: 'loading' | 'error' | 'success'
  benefitList: IUtilitiesItem[] | undefined
  coinPrice: IAllCoinPrice | undefined
  currency: {
    icon: '₩' | '$'
    name: 'USD' | 'KRW'
  }
}

const MarketOnSaleCardSkelton = () => {
  return (
    <div className="bg-bgSecondary rounded-xl p-2 flex gap-x-3 shrink-0 w-full h-[96px]">
      <div className="w-20 h-20 bg-bgQuarternary rounded-xl shrink-0" />

      <div className="w-full">
        <div className="w-[140px] h-[22px] bg-bgQuarternary rounded shrink-0" />
        <div className="w-[72px] h-[8px] bg-bgQuarternary rounded shrink-0 mt-2" />
      </div>
    </div>
  )
}

const MarketOnSaleCard = ({
  image,
  minPrices,
  tokenId,
  currency,
  itemTitle,
  coinPrice,
  hasBenefit,
  benefitList,
  listingPrice,
  listingPaymentType,
  collectionTitle,
  collectionAddress,
  previousTradingPrice,
  previousTradingPaymentType,
  isLoadingByFetchUtilities,
}: IMarketOnSaleCardProps) => {
  const { t } = useTranslation('common')
  const { push } = useRouter()

  const benefitItemInfo = getBenefitItemInfo({
    tokenId,
    hasBenefit,
    benefitList,
    collectionAddress,
  })

  const currencyCoinPrice = convertBalanceToCurrencyWithSymbol({
    currency,
    coinPrice,
    paymentType: listingPaymentType,
    price: listingPrice,
  })

  const handleNavigateToItemDetail = useCallback(
    () =>
      push(
        ROUTE_PATH.ITEM_DETAIL.replace(':collectionAddress', collectionAddress).replace(
          ':tokenId',
          tokenId,
        ),
      ),
    [collectionAddress, tokenId, push],
  )

  return (
    <div className="p-2 mt-4 bg-bgSecondary rounded-xl">
      <div className="p-2" onClick={handleNavigateToItemDetail}>
        <div className="flex w-full gap-4 mb-4 shrink-0">
          <MarketThumbnail url={image} size={{ width: 'w-20', height: 'h-20' }} />

          <div>
            <p className="w-full whitespace-nowrap font-medium text-sm text-textSecondary truncate">
              {collectionTitle}
            </p>
            <p className="mb-2 text-lg line-clamp-2 font-medium text-ellipsis text-textPrimary">
              {itemTitle}
            </p>

            <p className="text-lg font-semibold leading-[23px] text-appTextPrimary">
              {t('common:common.label-abt')} {`${currencyCoinPrice}`}
            </p>
            <PriceLabel
              size={'xs'}
              isShowPaymentType
              price={Number(listingPrice)}
              paymentType={listingPaymentType}
              priceTextStyle="text-appTextTertiary font-medium leading-[15px]"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <p className="text-sm text-textSecondary font-medium">
            {t('common:common.collection-previous-transaction-price')}
          </p>

          <PriceLabel
            size={'sm'}
            isShowPaymentType
            price={Number(previousTradingPrice)}
            paymentType={previousTradingPaymentType}
          />
        </div>

        <div className="flex items-center justify-between mt-1">
          <p className="text-sm text-textSecondary font-medium">
            {t('common:common.collection-current-floor-price')}
          </p>
          <PriceLabel
            size={'sm'}
            isShowPaymentType
            price={Number(minPrices[0].minPrice)}
            paymentType={minPrices[0].paymentType}
          />
        </div>
      </div>

      {isLoadingByFetchUtilities && !hasBenefit && <MarketOnSaleBenefit.Skelton />}
      {hasBenefit && <MarketOnSaleBenefit benefitItemInfo={benefitItemInfo} />}
    </div>
  )
}

export default MarketOnSaleCard
MarketOnSaleCard.Skelton = MarketOnSaleCardSkelton
