import { cx } from '@/shared/utils'

interface IMarketTitleProps {
  title: string
  subTitle?: string
  wrapperClassNames?: string
}

const MarketTitle = ({ title, subTitle, wrapperClassNames }: IMarketTitleProps) => {
  return (
    <div className={cx(wrapperClassNames)}>
      <p className={'text-2xl text-textAccent font-semibold'}>{title}</p>
      {subTitle && <p className="mt-1 text-sm text-textSecondary">{subTitle}</p>}
    </div>
  )
}

export default MarketTitle
