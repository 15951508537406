import 'swiper/css'
import 'swiper/swiper.min.css'
import 'swiper/css/pagination'
import 'swiper/css/effect-coverflow'
import 'swiper/swiper-bundle.min.css'

import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { range } from 'lodash'
import { Suspense } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import SwiperCore, { Pagination } from 'swiper'

import { CommonErrorFallback } from '@/components/common'
import { useGetCollectionRankingInfo } from '@/shared/services/market'
import { ICollectionRankingItem } from '@/shared/types'

import MarketTitle from '../common/MarketTitle'
import MarketRankingCard from './MarketRankingCard'

SwiperCore.use([Pagination])

const MarketRankingSkelton = () => {
  const { t } = useTranslation('common')
  return (
    <div className="p-4 mt-6">
      <MarketTitle title={t('common.collection-weekly-popular-collection-title')} />

      <div className="flex flex-col mt-4">
        {range(5).map(index => (
          <MarketRankingCard.Skelton key={`market-trade-ranking-skeleton-card-${index}`} />
        ))}
      </div>
    </div>
  )
}

const MarketRankingErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation(['common'])
  return (
    <div className="p-4 mt-6 bg-bgPrimary">
      <MarketTitle
        title={t('common.collection-weekly-popular-collection-title')}
        wrapperClassNames={'mb-4'}
      />
      <CommonErrorFallback
        error={error}
        bgColor="bg-bgSecondary"
        title={t('common.error-data')}
        handleRetry={resetErrorBoundary}
      />
    </div>
  )
}

const MarketRankingDesign = ({ data }: { data: ICollectionRankingItem[] }) => {
  const totalSlideDataCount = data.length
  const isEmptySlideData = totalSlideDataCount === 0
  const { t } = useTranslation('common')

  if (isEmptySlideData) {
    return <></>
  }

  return (
    <section className="p-4 mt-6 bg-bgPrimary">
      <MarketTitle
        title={t('common.collection-weekly-popular-collection-title')}
        wrapperClassNames={'mb-4'}
      />

      {data.map((collectionRankingItem, index) => {
        const isLastItem = index === data.length - 1
        return (
          <MarketRankingCard
            {...collectionRankingItem}
            key={`Market-Ranking-Card-${index}`}
            isLastItem={isLastItem}
          />
        )
      })}
    </section>
  )
}

const MarketRankingWithErrorBoundary = () => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ErrorBoundary onReset={reset} FallbackComponent={MarketRanking.ErrorFallback}>
      <Suspense fallback={<MarketRanking.Skeleton />}>
        <MarketRanking />
      </Suspense>
    </ErrorBoundary>
  )
}

const MarketRanking = () => {
  const SHOW_DATA_COUNT = 5
  const { data } = useGetCollectionRankingInfo({ itemCount: SHOW_DATA_COUNT })

  if (data === undefined) return null

  return <MarketRanking.Design data={data.tradeRanking} />
}

export default MarketRanking

MarketRanking.Design = MarketRankingDesign
MarketRanking.Skeleton = MarketRankingSkelton
MarketRanking.ErrorFallback = MarketRankingErrorFallback
MarketRanking.WithErrorBoundary = MarketRankingWithErrorBoundary
