import Image from 'next/image'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BenefitStatusLabel } from '@/components/common'
import { IUtilitiesItem } from '@/shared/services/market'
import { cx } from '@/shared/utils'

interface IBenefitItemProps {
  benefitItemInfo: IUtilitiesItem | null
}

const MarketOnSaleBenefitSkelton = () => {
  return <div className="w-full h-[84px] bg-bgQuarternary rounded shrink-0" />
}

const BenefitItem = ({
  title,
  status,
  order,
}: {
  title: string
  status: boolean
  order: number
}) => {
  const { t } = useTranslation('common')
  return (
    <li className="flex items-center justify-between py-2">
      <div className="flex items-center gap-x-1">
        <p className="text-xs text-textSecondary font-medium mr-2">
          {t('common.item-benefit-number').replace('{value}', `${order + 1}`)}
        </p>
        <p className="max-w-[200px] text-sm text-textPrimary truncate font-medium">{title}</p>
      </div>

      <BenefitStatusLabel status={status} />
    </li>
  )
}

const MarketOnSaleBenefit = ({ benefitItemInfo }: IBenefitItemProps) => {
  const [isShowBenefit, setIsShowBenefit] = useState(false)
  const { t } = useTranslation('common')

  if (
    benefitItemInfo === null ||
    benefitItemInfo.benefitItems === null ||
    benefitItemInfo.benefitItems.length === 0
  ) {
    return <></>
  }

  const { benefitItems } = benefitItemInfo

  return (
    <>
      <div
        id="accordion-collapse"
        data-accordion="collapse"
        className="w-full mt-2 bg-bgTertiary rounded-xl accordion px-4 py-3"
        onClick={() => setIsShowBenefit(!isShowBenefit)}>
        <div className="accordion-item w-full flex justify-between">
          <div className="flex items-center gap-x-2">
            <p className="text-xs text-textSecondary font-medium">
              {t('common.item-on-sale-item-benefit')}
            </p>
            <p className="text-xs text-textAccent font-medium">{`${benefitItems.length}${t(
              'common.unit-count',
            )}`}</p>
          </div>
          <Image
            width={16}
            height={16}
            src={isShowBenefit ? '/img/icon/chevron-up.svg' : '/img/icon/chevron-down.svg'}
            alt="chevron-icon"
          />
        </div>
        {isShowBenefit && (
          <ul
            className={cx(
              isShowBenefit ? 'w-full mt-[10px] border-t border-borderQuarternary' : '',
            )}>
            {benefitItems.map((item, index) => (
              <BenefitItem
                order={index}
                title={item.benefitTitle}
                status={item.benefitStatus}
                key={`Market-OnSale-Benefit-${index}-${item.benefitTitle}`}
              />
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

export default MarketOnSaleBenefit
MarketOnSaleBenefit.Skelton = MarketOnSaleBenefitSkelton
